.input-rounded {
  @apply border border-iron-500 p-4 text-center font-light text-xl rounded-full focus:outline-none focus:border-basil-500;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &.input-rounded--error {
    @apply border-error-500 text-error-500;
  }
}
