@mixin kwp-button() {
  @apply text-base px-6 py-2 font-medium text-white fill-white rounded transition-all duration-500 ease-in-out cursor-pointer;

  &[disabled] {
    @apply cursor-not-allowed opacity-60;
  }
}

.kwp-button-primary {
  @include kwp-button();

  @apply bg-basil-500 hover:bg-basil-900;
}

.kwp-button-tertiary {
  @include kwp-button();

  @apply bg-white hover:bg-basil-900 hover:text-white hover:fill-white border border-basil-500 text-basil-500 fill-basil-500;
}

.kwp-button-secondary {
  @include kwp-button();

  @apply bg-gray-900 border-gray-900 border hover:bg-gray-700;
}

@mixin kwp-input() {
  @apply flex flex-col;

  > div {
    @apply flex items-center bg-white px-3 py-2 rounded border border-gray-900 transition;

    &:not(.disabled) {
      @apply hover:border-basil-900 focus-within:border-basil-500;
    }

    &.disabled {
      @apply opacity-60;
    }

    input,
    textarea {
      @apply text-gray-900 bg-transparent outline-none text-sm leading-6 w-full min-h-[24px];

      &::placeholder {
        @apply text-iron-500;
      }

      &::-webkit-date-and-time-value {
        // KEB-2660 required for iOS input height bug https://bugs.webkit.org/show_bug.cgi?id=198959
        @apply h-6;
      }
    }
  }
}

.kwp-inline-progress-spinner {
  $smallProgressSize: 26px;
  min-width: $smallProgressSize !important;
  min-height: $smallProgressSize !important;

  width: $smallProgressSize !important;
  height: $smallProgressSize !important;

  margin: calc((36px - #{$smallProgressSize}) / 2) calc((88px - #{$smallProgressSize}) / 2);
}

.kwp-data-view-page {
  @apply my-6 mx-4 sm:my-10 sm:mx-8;
}

.kwp-card {
  @apply flex flex-col p-8 rounded bg-white shadow-keba-light overflow-hidden;
}

.kwp-card-clickable {
  @extend .kwp-card;
  @apply hover:shadow-basil-500 border-white border transition cursor-pointer hover:border-basil-900;

  &:hover {
    box-shadow: 4px 8px 30px 0 rgba(37, 97, 17, 0.21);
  }
}

.kwp-inline-progress-spinner {
  $smallProgressSize: 26px;
  min-width: $smallProgressSize !important;
  min-height: $smallProgressSize !important;

  width: $smallProgressSize !important;
  height: $smallProgressSize !important;

  margin: calc((36px - #{$smallProgressSize}) / 2) calc((88px - #{$smallProgressSize}) / 2);
}

.mat-calendar-body-selected {
  @apply text-white;
}

.kwp-section-header {
  @apply p-2 font-bold text-gray-900 text-sm bg-gray-200 w-full mb-6 rounded sticky top-0 z-10;
}

.kwp-group-header {
  @apply p-2 font-bold text-gray-900 text-sm w-full mb-4;
}

* {
  &::-webkit-scrollbar {
    @apply block w-2 h-2;
  }

  *::-webkit-scrollbar-button {
    @apply hidden;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track-piece {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00000040;
    border: none;
    border-radius: 24px;
  }
}
.mat-mdc-tooltip {
  &::before {
    @apply relative inline-block bg-white w-5 h-5 -rotate-90 top-12  #{!important};
    content: '';
    clip-path: polygon(50% 0, 0 50%, 50% 100%);
    left: 80% !important;
  }
}
.mat-mdc-tooltip {
  @apply shadow-2xl bg-white rounded-2xl p-2.5 -left-12 #{!important};

  .mdc-tooltip__surface {
    @apply bg-white font-light text-sm text-gray-900 #{!important};
  }
}
