/* For mobile and screens smaller than 768px */
.cdk-overlay-pane.full-screen {
  @apply max-h-screen min-h-screen;
  max-width: 100vw !important;
  min-width: 100vw !important;

  .mat-dialog-container {
    background: none;
    @apply p-0;
  }
}

// /* For screens with a minimum width of 768px (md) */
// @media screen and (min-width: 768px) {
//   .cdk-overlay-pane.full-screen {
//     @apply max-h-screen min-h-screen;
//     max-width: none !important;
//   }
// }

.mat-mdc-dialog-surface::-webkit-scrollbar {
  @apply hidden;
}

.overflow-unset {
  overflow: unset !important;
}

.mat-mdc-dialog-container {
  @apply h-full shadow-none bg-none bg-transparent border-r-0 p-0 #{!important};

  .mdc-dialog__surface {
    @apply bg-none bg-transparent #{!important};
  }

  .mdc-dialog__content {
    line-height: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit !important;
  }

  @media (min-width: 1024px) {
    @apply h-auto;
  }
}
