.kw-progress-spinner-white {
  &.mat-mdc-progress-spinner circle,
  &.mat-mdc-spinner circle {
    stroke: #fff !important;
  }
}

.kw-progress-spinner-gray {
  &.mat-mdc-progress-spinner circle,
  &.mat-mdc-spinner circle {
    stroke: #848d92 !important;
  }
}

.kw-progress-spinner-blue {
  &.mat-mdc-progress-spinner circle,
  &.mat-mdc-spinner circle {
    stroke: rgb(51, 132, 255) !important;
  }
}

.kw-progress-spinner-green {
  &.mat-mdc-progress-spinner circle,
  &.mat-mdc-spinner circle {
    stroke: rgb(82, 174, 50) !important;
  }
}
