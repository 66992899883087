.mat-mdc-form-field-flex {
  @apply rounded-md shadow-keba-light items-center px-4 py-2 bg-white #{!important};
}

.mdc-line-ripple {
  @apply hidden #{!important};
}

.mat-mdc-form-field-should-float .mat-mdc-floating-label {
  @apply hidden #{!important};
}
.mat-mdc-focused .al-subscribe-form-placeholder,
.mat-mdc-form-field-invalid .al-subscribe-form-placeholder {
  @apply hidden #{!important};
}

.mat-mdc-form-field-infix {
  @apply p-0 min-h-0 border-0 #{!important};
}

.mat-mdc-form-field-subscript-wrapper {
  @apply hidden #{!important};
}

.mat-mdc-text-field-wrapper {
  @apply p-0 rounded-md #{!important};
}

.mat-mdc-form-field-focus-overlay {
  @apply hidden #{!important};
}
