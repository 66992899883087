.toast-top-center {
  top: 4rem !important;
}

.toast-inline {
  display: inline;
  position: static !important;

  .kwa-notification {
    padding: 1px !important; // fix for cut-off when using inline
  }
}
