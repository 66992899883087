@mixin kwp-button() {
  @apply p-3 px-11 text-xl font-medium text-white rounded-full transition-all duration-500 ease-in-out cursor-pointer;

  &[disabled] {
    @apply cursor-not-allowed opacity-60;
  }

  &.small {
    @apply text-base px-6 p-2;
  }

  &:not(.small) {
    @apply p-[13px] px-8 h-[50px] text-lg
  }

  &:focus {
    @apply outline-none;
  }
}

.button-primary-shadow {
  @include kwp-button();
  @apply bg-basil-500 hover:bg-basil-900 shadow-green;
}

.button-primary {
  @include kwp-button();
  @apply bg-basil-500 hover:bg-basil-900;
}

.button-secondary {
  @include kwp-button();
  @apply bg-gray-900 hover:bg-gray-1000;
}

.base-link {
  &:disabled {
    @apply opacity-50;
  }
}

.link-white {
  @apply base-link font-medium text-base border-b border-white text-white h-6 transition-all duration-500 ease-in-out cursor-pointer;
}

.link-primary {
  @apply base-link font-medium text-base border-b border-basil-500 text-basil-500 hover:text-basil-900 hover:border-basil-900 transition-all duration-500 ease-in-out cursor-pointer;
}

.link-secondary {
  @apply base-link font-medium text-base border-b border-gray-900 text-gray-900 hover:text-gray-1000 hover:border-gray-1000 transition-all duration-500 ease-in-out cursor-pointer;
}

.link-alert {
  @apply base-link font-medium text-base border-b border-error-500 text-error-500 transition-all duration-500 ease-in-out cursor-pointer;
}

.button-tab-primary {
  @apply p-4 border rounded-md bg-white text-basil-500 border-basil-500;
  &.active {
    @apply bg-basil-500 text-white;
  }
}

button:disabled {
  @apply cursor-not-allowed bg-opacity-50 #{!important};
}
