.is-loading {
  .preview {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
    min-width: 100px;
    * {
      opacity: 0;
    }
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
