@layer base {
  h1,
  h2,
  h3,
  h4 {
    height: inherit !important;
    font-spacing: 0.01em;
  }

  .h-0 {
    @apply font-extralight leading-1.1;
    font-size: 80px;
    height: auto !important;
  }

  h1 {
    @apply font-extralight leading-1.1;
    font-size: 40px;
  }

  h2 {
    @apply font-extralight text-2xl leading-1.2;
  }

  h3,
  h4 {
    @apply font-medium leading-1.2;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }
}

.body-small,
.body-tiny {
  @apply font-light leading-6;
}

.body-small {
  @apply text-sm;
}

.body-tiny {
  @apply text-xs;
}

.code {
  @apply text-xs;
  font-family: 'Courier New', Courier, monospace;
}
