@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

// CUSTOM COMPONENTS
@use 'components/material-dialog';
@use 'components/material-input';
@use 'components/button';
@use 'components/toast';
@use 'components/forms';
@use 'components/spinner';
@use 'components/animations';
@use 'components/sidenav';

// CUSTOM UTILITIES
@use 'utilities/typography';

@use '@angular/cdk/overlay-prebuilt';

html {
  @apply text-gray-900;
  scroll-behavior: smooth;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.55);
}

/* Custom scrollbar style */
.custom-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.custom-scrollbar {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
